import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Particles from "./components/Particles.tsx";
import Projects from "./components/Projects.tsx";
import Contact from "./components/Contact.tsx";
import TransitionWrapper from "./util/TransitionWrapper.tsx";
import MyFooter from "./components/Footer.tsx";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Projects", href: "/projects" },
  { name: "Contact", href: "/contact" },
];

function Home() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-[70vh] lg:h-[70vh] xl:h-[80vh] overflow-hidden overflow-y-auto bg-gradient-to-tl from-black via-zinc-600/20 to-black pt-20">
      <div className="w-screen h-px animate-glow md:block animate-fade-left bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
      <h1 className="py-3.5 px-0.5 z-10 text-4xl text-transparent duration-1000 bg-white cursor-default text-edge-outline animate-title font-display sm:text-6xl md:text-8xl lg:md:text-9xl whitespace-nowrap bg-clip-text">
        Aman Bhayani
      </h1>
      <div className="w-screen h-px animate-glow md:block animate-fade-right bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
      <div className="my-16 text-center animate-fade-in">
        <h2 className="text-sm text-zinc-500">
          Transforming Challenges into Digital Experiences.
        </h2>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <TransitionWrapper>
        <div className="flex flex-col min-h-screen">
          {/* Fixed Navigation */}
          <nav className="my-4 bg-black bg-opacity-70 py-4">
            <ul className="flex items-center justify-center gap-4">
              {navigation.map((item) => (
                <Link
                  key={item.href}
                  to={item.href}
                  className="text-sm duration-500 text-zinc-500 hover:text-zinc-300 cursor-pointer"
                >
                  {item.name}
                </Link>
              ))}
            </ul>
          </nav>

          {/* Particles Background */}
          <Particles
            className="absolute inset-0 -z-10 animate-fade-in overflow-hidden h-full"
            quantity={100}
          />

          {/* Main Content with Flex Grow */}
          <main className="md:pt-0 md:pb-0">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>

          {/* Footer */}
          <MyFooter/>
        </div>
      </TransitionWrapper>
    </Router>
  );
}

export default App;
