import React, { useEffect, useState } from "react";

function Modal({ isOpen, onClose, title, href, children }) {
    const [isAnimating, setIsAnimating] = useState(false); // For controlling animation state

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true); // Start animation when modal opens
        } else {
            setTimeout(() => setIsAnimating(false), 300); // Delay hiding for exit animation
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isAnimating) {
            setTimeout(() => onClose(), 300);
        }
    }, [isAnimating])

    // Render null if not animating and modal is closed
    if (!isOpen && !isAnimating) return null;

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${isAnimating ? 'opacity-100' : 'opacity-0'}`}>
            <div style={{ maxHeight: '50rem' }}
                className={`bg-white rounded-lg shadow-lg w-11/12 md:w-[800px] lg:w-[900px] xl:w-[1000px] p-6 transform transition-transform duration-300 overflow-y-auto max-h-96 ${isAnimating ? 'scale-100 opacity-100' : 'scale-90 opacity-0'}`}
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">{title}</h2>
                    <button onClick={() => setIsAnimating(false)} className="text-gray-500 hover:text-gray-700 text-xl">
                        &times;
                    </button>
                </div>
                <div>{children}</div>

                <div className="flex justify-end gap-4 items-center mb-4">
                    { href && <button
                        onClick={() => window.open(href, '_blank')}
                        className="mt-4 w-32 bg-blue-500 text-white py-2 hover:bg-blue-600 transition"
                    >
                        View
                    </button>}
                    <button
                        onClick={() => setIsAnimating(false)}
                        className="mt-4 w-16 bg-blue-500 text-white py-2 hover:bg-blue-600 transition"
                    >
                        Close
                    </button>

                </div>
            </div>
        </div>
    );
}

export default Modal;
