import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import Card from './Card.tsx';
import Modal from '../util/Modal.tsx';
import Carousel from './Carousel.tsx';
import CheckMate from "../assets/imgs/CheckMate/Cover.JPG"
import CheckmateGif from "../assets/imgs/CheckMate/CheckMate-WalkThrough.gif"
import AcrobatForms from "../assets/imgs/AcrobatForms/Cover.JPG"
import wheresCovid from "../assets/imgs/WheresCovid/3.png";
import HissperTitle from "../assets/imgs/Hissper/1.png";
import DataVisual from "../assets/imgs/DataVisualizer/title.png";
import Acupucture from "../assets/imgs/Acupuncture/cover.jpg";
import AEMplugin from "../assets/imgs/LanguagePlugin/1.png";


// Import all images from the folder
const importAllImages = (requireContext) =>
    requireContext.keys().map(requireContext);

const AEMImages = importAllImages(require.context('../assets/imgs/LanguagePlugin', false, /\.(png|jpe?g|svg)$/));
const AcrobatImages= importAllImages(require.context('../assets/imgs/AcrobatForms', false, /\.(png|jpe?g|svg|gif)$/));
const WheresCovidImages = importAllImages(require.context('../assets/imgs/WheresCovid', false, /\.(png|jpe?g|svg)$/));
const HissperImages = importAllImages(require.context('../assets/imgs/Hissper', false, /\.(png|jpe?g|svg)$/));
const DataVisualImages = importAllImages(require.context('../assets/imgs/DataVisualizer', false, /\.(png|jpe?g|svg)$/));

console.log("DataVisualImages", DataVisualImages)

const projectsData = [
    {
        id: 0,
        imgURL: CheckMate,
        title: "CheckMate",
        tags: ["Spring Boot", "Postgres", "Next.js"],
        details: "CheckMate is an engineered service that streamlines event management by automatically\
         matching ticket purchasers to members for verification through uploaded CSV files.\
          Users can also manually link unmatched guests to members, ensuring a smooth and accurate guest verification process.",
        screenshots: [CheckmateGif],
        href: "https://github.com/amanster82/checkmate-app"

    },
    {
        id: 2,
        imgURL: AEMplugin,
        title: "AEM Language Code Plugin",
        tags: ["JavaScript", "HTL", "Java", "AEM"],
        details: "As part of my role at Scotiabank.com, I developed an AEM (Adobe Experience Manager) Language Code plugin,\
            a tool to streamline content accessibility and SEO directly within AEM, a powerful CMS for large-scale websites.\
            This plugin, represented by a globe icon in the RichText editor, enables page authors in editing mode to highlight text,\
            specify a language code (like \"Fr\" for French), and apply it. The feature wraps the selected text in a `<span>` \
            tag with a `lang` attribute set to the specified code, which is vital for SEO, accessibility, and screen reader clarity. \
            This tool empowers Scotiabank to optimize multilingual content and align with accessibility standards more efficiently.",
        screenshots: AEMImages,
        href: false
    },
    {
        id: 3,
        imgURL: Acupucture,
        title: "doctoryu.ca",
        tags: ["React", "JavaScript", "Google Analytics"],
        details: "I developed *doctoryu.ca* to revitalize an established client’s online presence, catering to their goals\
         for increased visibility and seamless online booking. Using JavaScript and React, I transformed their aging website \
         into a modern, responsive platform. By integrating Google Analytics, I also enabled them to track customer interactions,\
          enhancing their ability to understand and engage with their audience effectively. This overhaul not only aligned\
           with the latest web standards but also provided powerful, actionable insights into client behavior.",
        screenshots: [Acupucture],
        href: "https://www.doctoryu.ca/"
    },
    {
        id: 4,
        imgURL: AcrobatForms,
        title: "Dynamic Acrobat Forms",
        tags: ["JavaScript", "Adobe Acrobat API"],
        details: "Upon graduating, I was hired by the Provincial Health Services Authority (PHSA) on a contract to address a crucial issue: supporting school-aged children with diabetes who require consistent blood glucose monitoring while at school. Nurses were struggling with care plans that were created as freeform Microsoft Word documents, which varied widely in format and were time-consuming to produce and maintain. My solution was to develop a standardized, dynamic Adobe Acrobat Form that could be tailored to each child’s specific needs yet maintained consistent formatting.\
        The form allowed nurses to easily select various options from pre-set choices using interactive fields, carousels, and dropdowns, \
        reducing the need for manual input and ensuring accuracy. Built using Acrobat JavaScript, the form fields and buttons overlaid the static document,\
         enabling a layer of dynamic functionality. Once completed, these care plans could be printed and shared with school staff, who then had a clear and \
         organized guide to support each child's care. Years later, PHSA invited me back to modernize and update a series of newer forms,\
          reflecting both my initial work's impact and their trust in my expertise.",
        screenshots: AcrobatImages,
        href: false
    },
    {
        id: 5,
        imgURL: wheresCovid,
        title: "WheresCovid",
        tags: ["Express", "Postgres", "React"],
        details: "WheresCovid.ca* is a web application designed to track and report COVID-19 cases in Canada based on FSA (Forward Sortation Area) codes. After creating an account and completing a short questionnaire (including COVID-19 status and partial postal code), users are given a dashboard displaying COVID-19 case data specific to their geographic area.\
        The app is built with Node.js, PostgreSQL, and MySQL for data storage and management,\
        and is designed for easy local setup and deployment.",
        screenshots: WheresCovidImages,
        href: "https://github.com/amanster82/Epidemic-Status-Tracker"
    },
    {
        id: 6,
        imgURL: HissperTitle,
        title: "Hissper",
        tags: ["Flask", "Flask-SocketIO", "Gunicorn", "Eventlet"],
        details: "Hissper is a global web chat application that enables users to create and join chatrooms,\
         connecting with others worldwide. Built with a full-stack approach, the back end is powered by Python and network sockets for real-time communication,\
         while the front end uses HTML, CSS, and JavaScript for an interactive and responsive user experience.",
        screenshots: HissperImages,
        href: "https://github.com/linksolutions/App"
    },
    {
        id: 7,
        imgURL: DataVisual,
        title: "NexGen",
        tags: ["Koa.js", "GoJS", "MySQL", "Web Components"],
        details: "NexGen, a web application that provides a way to visualize data from any MySQL database.\
        This project showcases experimental technology using web-components. This app was done in a team of 4.\
        Key technologies used were: HTML, CSS, Javascript, Node.js, Koa.js.",
        screenshots: DataVisualImages,
        href: "https://github.com/amanster82/SENG_371"
    }
]


function Projects(props) {
    const [isModalOpen, setIsModalOpen] = useState({ open: false, title: "", details: "", screenShots: [], href: false});
    const openModal = (obj) => { console.log("obj", obj); setIsModalOpen(obj) };
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="flex flex-col items-center justify-center overflow-hidden">
            <div className="w-10/12 flex flex-col items-center pb-8">
                <FontAwesomeIcon icon={faCoffee} color="white" /> Coffee Time!
                <h1 className="text-white text-2xl md:text-4xl font-bold mb-4 text-center">
                    Projects
                </h1>

                {/* Project Year */}
                <div className="flex flex-row justify-center sm:justify-start w-2/4 md:w-5/6 mb-4">
                    <h2 className="text-white text-lg md:text-xl font-semibold">Recent Projects</h2>
                </div>

                {/* Cards Container */}
                <div className="flex flex-wrap items-center justify-center gap-4 md:gap-6 lg:gap-8 w-8/12">
                    {projectsData.slice(0, 5).map(item =>
                        <Card
                            key={item.id}
                            openModal={openModal}
                            title={item.title}
                            tags={item.tags}
                            imageUrl={item.imgURL}
                            details={item.details}
                            screenShots={item.screenshots}
                            href={item.href}
                        />
                    )}

                </div>

                <br></br>
                <br></br>
                <div className="flex flex-row justify-center sm:justify-start w-2/4 md:w-5/6 mb-4">
                    <h2 className="text-white text-lg md:text-xl font-semibold">Other Projects</h2>
                </div>

                <div className="flex flex-wrap items-center justify-center gap-4 md:gap-6 lg:gap-8 w-8/12">
                    {projectsData.slice(5).map(item =>
                        <Card
                            key={item.id}
                            openModal={openModal}
                            title={item.title}
                            tags={item.tags}
                            imageUrl={item.imgURL}
                            details={item.details}
                            screenShots={item.screenshots}
                            href={item.href}
                        />
                    )}

                </div>

                <Modal isOpen={isModalOpen.open} onClose={closeModal} title={isModalOpen.title} href={isModalOpen.href}>
                    {isModalOpen?.screenShots?.length > 1 && <Carousel images={isModalOpen.screenShots} />}
                    {isModalOpen?.screenShots?.length === 1 && (
                        <img
                            src={isModalOpen.screenShots[0]}
                            alt="Screenshot"
                            className="w-full max-w-[600px] h-auto object-contain mx-auto mb-4"
                        />
                    )}
                    <p className="text-gray-700">
                        {isModalOpen.details}
                    </p>
                </Modal>


            </div>
        </div>
    );
}


export default Projects;