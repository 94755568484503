import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

function MyFooter() {
    function handleExternalLink(url) {
        window.location.href = url;
      }
    return (
        <footer className="flex-grow bg-black bg-opacity-80 text-white py-4 flex items-center justify-center gap-8 w-full">
            <Link 
                className="hover:scale-150 hover:text-teal-900 transition-all duration-700"
                to="/contact" 
            >
                <FontAwesomeIcon 
                    icon={faEnvelope} size="2x" />
            </Link>
            <button 
                className="hover:scale-150 hover:text-indigo-800 transition-all duration-700"
                onClick={() => handleExternalLink('https://www.linkedin.com/in/amanbhayani/')}>
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </button>
            <button 
                className="hover:scale-150 hover:text-yellow-500 transition-all duration-700"
                onClick={() => handleExternalLink('https://github.com/amanster82/')}>
                <FontAwesomeIcon icon={faGithub} size="2x" />
            </button>
        </footer>
    );
}

export default MyFooter;

