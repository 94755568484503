import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactCard() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sentPrompt, setSentPrompt] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send('service_c0f0d72', 'template_5pwszk2', {
            name: name,
            email: email,
            message: message,
        }, 'MVFKIHbSQTvgh_iKA')
        .then((response) => {
            console.log('Email successfully sent!', response.status, response.text);
            setSentPrompt(true);
            setName("");
            setEmail("");
            setMessage("");
            setTimeout(() => setSentPrompt(false), 3000);
        })
        .catch((error) => console.error('Error sending email:', error));
    };

    return (
        <div className="flex flex-col items-center p-4 sm:p-6 md:p-8 lg:p-10">
            <div
                className="relative flex flex-col justify-center bg-white bg-opacity-10 backdrop-blur-lg border border-white border-opacity-20 rounded-lg p-6 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl transform transition duration-300 hover:shadow-lg"
            >
                <h3 className="text-lg sm:text-xl font-semibold text-white mb-4 text-center">
                    Have a question or want to work with me?
                    <br />
                    Send me a message, and I’ll get back to you as soon as possible.
                </h3>
                <div className="absolute inset-0 bg-gradient-to-br from-purple-500 to-blue-500 opacity-20 rounded-lg blur-xl -z-10 transition-opacity duration-300 hover:opacity-40"></div>

                <form onSubmit={handleSubmit} className="relative space-y-4 mt-4">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        className="w-full p-3 rounded bg-white bg-opacity-20 text-white focus:outline-none"
                        required
                    />
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="w-full p-3 rounded bg-white bg-opacity-20 text-white focus:outline-none"
                        required
                    />
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Your Message"
                        className="w-full p-3 rounded bg-white bg-opacity-20 text-white focus:outline-none h-24 sm:h-32"
                        required
                    />
                    <button
                        type="submit"
                        className={`${
                            sentPrompt && 'invisible'
                        } px-4 py-2 bg-purple-500 hover:bg-purple-600 text-white font-semibold rounded transition`}
                    >
                        Send Message
                    </button>

                    {sentPrompt && (
                        <div
                            className="absolute inset-x-0 bottom-2 animate-fade-in-faster animate-pulse flex justify-center items-center text-white"
                        >
                            <div className="bg-emerald-800 px-4 py-2 rounded-lg">
                                <p>Email Sent</p>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default ContactCard;
