import React from "react";

function Card({ title, tags, imageUrl, openModal, details, screenShots, href }) {
    return (
        <div onClick={() => openModal({ open: true, title: title, details: details, screenShots: screenShots, href })} className="group flex flex-col justify-evenly relative bg-white bg-opacity-10 backdrop-blur-lg border border-white border-opacity-20 rounded-lg p-6 w-full sm:h-96 sm:w-80 transform transition duration-300 hover:scale-105 hover:shadow-lg active:scale-95 cursor-pointer">
            <img
                src={imageUrl}
                alt={title}
                className="w-full h-40 object-cover rounded-md mb-4"
            />
            <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>

            {/* Tags area */}
            <div className="flex flex-wrap gap-2 mb-4">
                {tags.map((tag, index) => (
                    <span
                        key={index}
                        className="text-xs font-medium text-white bg-purple-700 bg-opacity-50 px-2 py-1 rounded-full"
                    >
                        {tag}
                    </span>
                ))}
            </div>

            {/* Adding decorative animation */}
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500 to-blue-500 opacity-20 rounded-lg blur-xl -z-10 transition-opacity duration-300 group-hover:opacity-40"></div>
        </div>

    );
}

export default Card;
