import React from 'react';
import ContactCard from './ContactCard.tsx';

function Contact(props) {
    return (
        <div className='flex flex-item justify-center'>
            <ContactCard/>
        </div>
    );
}

export default Contact;