import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function TransitionWrapper({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to handle navigation with transition
    const handleRouteChange = (e) => {
      e.preventDefault(); // Prevent default navigation
      const target = e.currentTarget.getAttribute("href");

      // Start view transition
      if (document.startViewTransition) {
        document.startViewTransition(() => navigate(target));
      } else {
        navigate(target); // Fallback if View Transitions API is not supported
      }
    };

    // Attach event listeners to all anchor tags
    const links = document.querySelectorAll("a");
    links.forEach((link) => link.addEventListener("click", handleRouteChange));

    // Cleanup event listeners on component unmount
    return () => {
      links.forEach((link) => link.removeEventListener("click", handleRouteChange));
    };
  }, [navigate]);

  return (
    <div className="transition-wrapper">
      {children}
    </div>
  );
}

export default TransitionWrapper;
